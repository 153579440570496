$primaryDark: #000724;
$primaryRed: #b0002f;

.react-widget-wrapper.latest-news-widget {
  .list-item {
    margin-top: var(--space-4);
    &:not(:first-of-type) {
      padding-top: var(--space-4);
      border-top: 1px solid var(--c-shade-10);
    }
    &.mobile {
      margin: var(--space-4) var(--space-4) 0 var(--space-4);
    }
  }
  .latest-news-teaser {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &:focus-visible {
      outline: auto;
      outline-color: blue;
    }
    .header {
      display: flex;
      align-items: baseline;
      gap: 8px;
      margin-bottom: var(--space-1);
      font: 400 12px/16px $fontGraphik;

      .formattedTime {
        font-weight: 600;
        text-transform: capitalize;

        &_old {
          color: $primaryDark;
        }

        &_fresh {
          color: $primaryRed;
          text-transform: unset;
        }
      }

      .category {
        color: var(--c-neutral-09);
        text-transform: capitalize;
      }
    }

    .title {
      font: 600 15px/19px $fontGraphik;
      letter-spacing: -0.3px;
      color: $primaryDark;
      grid-area: title;
    }

    &_mobile {
      flex-direction: row;
      gap: var(--space-4);
      justify-content: space-between;

      .text-content {
        width: 100%;

        &:has(+ .image) {
          width: calc(100% - 80px);
        }
      }

      .image {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        object-fit: cover;
      }
    }
  }

  .pagination-button-wrapper + .latest-news-teaser {
    margin-top: 0;
  }
}
